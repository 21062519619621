body {
    font-family: 'Open Sans', sans-serif !important;
}

#infosChantier {
    margin: 12px;
    padding: 12px;
    background-color: #bbbbbb;
    border-radius: 4px;
}

.ui-datepicker-trigger {
    cursor: pointer;
    margin: 0 10px;
    width: 24px;
}

.top-nav {
    a {
        color: #777777;
        font-family: "Open Sans", sans-serif;
    }
}

.calendrierForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datepickerContainer {
    img.ui-datepicker-trigger {
        float: right;
    }
}

#nav-wrapper {
    box-shadow: none;

    #nav>li {
        height: auto !important;

        // &:hover {
        //     background: rgba(0, 0, 0, 0.1);

        //     ul {
        //         display: block;

        //         li {
        //             list-style-type: none;

        //             a {
        //                 padding-left: 0;

        //                 span {
        //                     font-weight: 500;
        //                     text-transform: initial !important;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}

#content {
    background: #f0f0f0;

    .open-menu {
        //background: url("../images/menu-button.png") no-repeat;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-position: center;
        background-size: contain;
        display: none;
    }

    @media screen and (max-width: 768px) {
        left: 0 !important;

        .open-menu {
            display: block;
        }
    }
}

md-content {
    color: rgba(0, 0, 0, 0.87) !important;
}

.md-button {
    border-radius: 3px;
    box-sizing: border-box;
    color: currentColor;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    outline: 0;
    border: 0;
    display: inline-block;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 6px;
    margin: 6px 8px;
    line-height: 36px;
    min-height: 36px;
    background: 0 0;
    background-color: rgba(0, 0, 0, 0);
    white-space: nowrap;
    min-width: 88px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    font-style: inherit;
    font-variant: inherit;
    font-family: inherit;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}



body {
    span.field-block {
        display: inline-block;
        margin: 0 0 15px;
        white-space: nowrap;

        @media (max-width: 765px) {
            display: block;

            select,
            input[type="text"] {
                display: block;
                width: 100%;
            }
        }
    }

    .btn-block {
        small {
            display: block;
        }
    }

    select {
        max-width: 100%;
    }

    .title {
        padding-left: 20px;
        color: black;
        font-weight: 600;
    }

    #content {
        top: 0;
        left: 170px;

        .page {
            background: inherit;
            padding: 0px 5px;

            md-content {
                box-shadow: none;

                .table-dynamic {
                    background-color: rgb(255, 255, 255);
                    border-radius: 3px;
                    padding: 15px;

                    .table-filters {
                        .add-section {
                            display: flex;
                            justify-content: end;
                            padding-right: 0;
                            width: auto;

                            button {
                                color: rgb(255, 255, 255);
                                background-color: rgb(0, 131, 143);
                                height: 80px;
                            }
                        }
                    }

                    .table-bordered {
                        border: 1px solid #ddd;
                        background: white;

                        thead {
                            color: black;

                            th {
                                font-weight: 600;
                                border: 1px solid #ddd;

                                border-bottom-width: 2px;

                                .glyphicon.selected {
                                    color: #009688;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table-container {
        overflow: auto;
    }

    .modal_del_user,
    .modal_del_msg,
    .modal_pdf_msg {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        background: rgba(0, 0, 0, 0.5);

        #modal_dialog {
            position: relative;
            margin: 250px auto;
            max-width: 760px;
            min-height: 250px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: white;

            .title {
                font-weight: 600;
                text-align: center;
                color: black;
                font-size: 1.5rem;
                padding: 20px 50px;
            }

            input {
                border: 2px solid black;
                background: white;
                color: black;
                padding: 10px;
                min-width: 80px;
                margin: 15px;
                transition: 0.5s;

                &:hover {
                    color: white;
                    background: black;
                    //border: 2px solid white;
                }
            }
        }
    }
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.spinner-container {
    display: flex;
    justify-content: center;
}

.lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 15px;
    left: 29px;
    width: 3px;
    height: 9px;
    border-radius: 20%;
    background: #c6c6c6;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.lds-spinner {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.spinner-container-csv {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.5);
}

.lds-spinner-csv {
    position: relative;
    margin: 250px auto;
    max-width: 760px;
    min-height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lds-spinner-csv div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner-csv div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 15px;
    left: 29px;
    width: 3px;
    height: 9px;
    border-radius: 20%;
    background: #c6c6c6;
}

.lds-spinner-csv div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner-csv div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner-csv div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner-csv div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner-csv div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner-csv div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner-csv div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner-csv div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner-csv div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner-csv div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner-csv div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner-csv div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner-csv {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.bandeau-user.row {
    margin: 0;
}

.bandeau-user {
    a {
        display: block;
        background-color: #00838f;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        padding: 10px;
        border-radius: 3px;
    }
}

//404
.page-404 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >div {
        padding: 15px;
        margin-top: -100px;
        display: flex;
        flex-direction: column;

        .logo-404 {
            text-align: center;
            font-size: 20rem;
            font-weight: 700;

            @media screen and (max-width: 500px) {
                font-size: 18rem;
            }
        }

        .btn-content {
            display: flex;
            justify-content: center;

            button {
                color: white;
                background-color: #00838f;
                height: 60px;
            }
        }

        p {
            text-align: center;
        }

        h1 {
            text-align: center;
            text-transform: uppercase;
        }
    }
}

#saisies {
    .saisie {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        margin: 4px 0;
        padding: 10px;
        background-color: #d6d6d6;

        &:nth-of-type(2n) {
            background-color: #eeeeee;
        }

        .saisieRow {
            display: flex;
            //align-items: center;
            //justify-content: space-between;

            label {
                width: 190px;
                font-weight: bold;
            }
        }
    }
}

#nav-container{
    overflow: auto;
}