@media screen and (max-width: 765px) {
    #content {
        margin-top: 100px !important;
    }
}

.nav-style--alternative #nav>li>a>md-icon {
    svg {
        width: 30px;
        height: 30px;
        fill: #fff;
        margin: auto;
    }
}

.bandeau {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black !important;
}


#nav-container {
    //background: #e6e6e6 !important;
    top: 0 !important;
    width: auto !important;

    .burger {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px;
        z-index: 20;
        margin: 20px;
        cursor: pointer;

        @media screen and (max-width: 765px) {
            display: block;
        }

        &>span {
            position: absolute;
            top: 50%;
            margin-top: -1px;
            height: 4px;
            background: #FFFFFF;
            left: 0;
            right: 0;
            border-radius: 10px;

            &:after {
                content: "";
                position: absolute;
                top: -20px;
                left: 0;
                right: 0;
                border-radius: 10px;
                background: #FFFFFF;
                height: 4px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 20px;
                left: 0;
                right: 0;
                border-radius: 10px;
                background: #FFFFFF;
                height: 4px;
            }
        }

        &.ouvert {
            &>span {
                background-color: transparent;

                &:after {
                    transform: rotate(41deg);
                    transform-origin: 0 0;
                }

                &:before {
                    transform: rotate(-41deg);
                    transform-origin: 0 0;

                }
            }
        }
    }

    @media screen and (max-width: 765px) {
        left: 0 !important;
        top: 0;
        right: 0;
        bottom: auto;
        height: 100px;

        md-icon {
            display: none !important;
        }

        #nav a {
            color: #FFFFFF;
        }
    }

    .logo-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 50px;

        @media screen and (max-width: 765px) {
            margin: 0;
        }

        li {
            display: block !important;

            @media screen and (max-width: 765px) {
                padding: 10px !important;
            }
        }

        .logo-artim {
            background: #FFFFFF url("../img/LOGO_ARTIM.png") no-repeat center center;
            width: 120px;
            height: 120px;
            display: block;
            background-size: contain;
            border-radius: 100px;

            @media screen and (max-width: 765px) {
                width: 80px;
                height: 80px;
            }
        }
    }

    ul#nav>li {
        height: 80px;

        >a {
            height: 100%;
            text-decoration: none;
            //padding: 13px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &.active {
            ul {
                display: block;
            }
        }

        ul li a {
            padding: 13px 0;

            span {
                font-weight: 500;
                text-transform: initial !important;
            }
        }

        a>span {
            display: block;
            text-align: center;
            font-weight: 600;
            font-size: 1.4rem;
            text-transform: uppercase;
        }
    }



    @media screen and (max-width: 765px) {
        aside#nav-container #nav-wrapper {
            margin-top: -100vh;
            height: calc(100vh - 100px);
            background: #888888;

            &.ouvert {
                margin-top: 0;
            }
        }
    }
}


@media screen and (min-width: 765px) {
    #nav-container {
        overflow-y: scroll;
        float: left;
        width: 50%;
        height: calc(100vh + 30px);
        width: 180px !important;
        overflow-x: hidden;
    }
}

@media screen and (max-height: 850px) {
    #nav-container>.logo-content {
        margin-top: 0px;
        margin-bottom: 0px;
    }

}

@media screen and (max-height: 780px) {

    ul#nav>li>a {
        padding: 8px;
    }

    ul#nav ul>li>a {
        padding: 6px;
    }
}

/** TOP BAR **/
.top-nav {
    // display: flex;
    // justify-content: space-between;
    height: 100%;

    ul.list-notifications {
        margin-right: 0 !important;
    }

    .role-user {
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        font-size: 22px;
        padding: 0;
        margin: 0;
        color: #009688;
        font-weight: 700;
        text-align: center;

        @media screen and (max-width: 540px) {
            font-size: 18px;
            margin-left: 10px;
        }
    }
}