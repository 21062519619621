body {
    background-color: lightgray;

    .header-signin {
        padding: 50px 0;
    }

    .logo {
        display: flex;
        justify-content: center;

        .logo_artim {
            background: #FFFFFF url("../img/LOGO_ARTIM.png") no-repeat center center;
            background-size: auto;
            width: 120px;
            height: 120px;
            display: block;
            background-size: contain;
            border-radius: 100px;
        }
    }

    .connexion {
        display: flex;
        flex-direction: column;
        width: 10%;
    }

    .identifiant-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        .lbl-identifiant {
            width: 50%;
        }
    }

    .password-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        .lbl-pwd {
            width: 50%;
        }
    }

    .btn-sub {
        display: flex;
        justify-content: center;

        button {
            margin: 0;
            width: 160px;
        }
    }
}

/* FORGOT PWD */
.retour_pos {
    padding-top: 20px;
    width: 100%;
    color: green;

}

.retour_neg {
    padding-top: 20px;
    width: 100%;
    color: #FF0000;
}