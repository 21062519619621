.table-filters.user-filt {
    padding: 0 25px !important;

    @media screen and (max-width: 700px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .row,
    .row>div {
        display: flex;
        align-items: center;

        form {
            display: flex;
            align-items: center;

            label {
                margin-bottom: 0;
            }

            .search-user {
                width: auto;
                margin-left: 15px;
            }
        }

        @media screen and (max-width: 700px) {
            display: block;
        }
    }

    .col-filters {
        label {
            color: black;
            font-weight: 600;
        }

        select {
            width: 150px;
            height: 34px;
            color: black;
            margin-right: 15px;
        }
    }

    @media screen and (max-width: 1120px) {
        .col-filters {
            width: 66.66666667%;
            padding: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: initial !important;

            >div {
                margin-bottom: 15px;
            }
        }

        .add-section {
            width: 33.33334%;

            button {
                margin: 0;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .col-filters {
            width: 100%;
        }

        .add-section {
            padding-right: 15px !important;
        }

        .add-section,
        .add-section a,
        .add-section button {
            width: 100%;
        }
    }
}

.form-user-content {
    max-width: 1200px;
    padding: 20px;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
    border-radius: 3px;

    >div {
        margin-bottom: 10px;
        display: flex;

        @media screen and (max-width: 920px) {
            display: inherit;
        }
    }

    label {
        width: 200px;
        color: black;
    }

    span {
        color: black;
    }

    select,
    input:not([type='button']) {
        width: 220px;
        //height: 30px;
        color: black;
        margin-right: 15px;
    }

    /*
  .pwd-content,
  .verif-content,
  .email-content,
  .identifiant-content,
  .nom-station {
    display: flex;

    @media screen and (max-width: 920px) {
      display: inherit;
    }
  }
  */
}

.submit-user {
    //width: 150px;
    padding: 10px 25px;
    color: white;
    background-color: #00838f;
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 3px;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 30px;
}