@media print {
	// body {
	//     visibility: hidden;
	//     // width: 0;
	//     // height: 0;
	//     // margin: 0;
	//     // padding: 0;
	// }
	body {-webkit-print-color-adjust: exact;}
	body {
		height: auto;
	}

	body div#nav-container {
		display: none !important;
		//visibility: hidden !important;
	}

	body section#content > section#header {
		display: none !important;
		//visibility: hidden !important;
	}

	body .saisie-filt, body .table-filters {
		display: none !important;
		//visibility: hidden !important;
	}

	body .table-container {
		visibility: visible !important;
	}

	body > #content {
		position: static;
		overflow: visible;
		height: auto;
	}
	table.table-bordered th, table.table-bordered td, th, td, div.th{
		border:0 !important; //1px solid gray
	}
	.table-dynamic .table-bordered thead th>.th{
		padding:0 !important;
	}
	tr { page-break-inside:avoid !important; }
	tr    { page-break-inside:avoid !important; padding:0 5px !important; }
	td, th    { page-break-inside:avoid !important; padding:0 5px !important; }
	tr:nth-child(odd) td{ background-color: unset; box-shadow: inset 0 0 0 1000px #DDDDDD !important; }
	// tr:nth-child(odd) td{ background-color: #DDDDDD !important; }
	// table.table-bordered th{ background-color:#888888 !important; color:white !important; }
	table.table-bordered th{ background-color: unset; box-shadow: inset 0 0 0 1000px #888888 !important; color:white !important; }

	table {
		max-width: unset !important;
		width: unset !important;
		font-size:10px;
		border-spacing:0 !important;
		border:0 !important;

		.actions {
			display: none !important;
		}
		th span{
			display:none !important;
		}
		tr th:nth-child(9){
			display:none !important;
		}
		
		/*thead {
			display: none;
		}*/
		/*tr {
			display: block;
			margin:2em 0;
		}
		td {
			display: block;
		}*/
		/*td[colspan] {
			margin: -2em 0 2em;
		}
		td[data-head]{
			&:before{
				content:attr(data-head) ' : ';
				font-weight: 700;
			}
		}*/
	}
	.sf-toolbar {
		display: none !important;
	}
}
