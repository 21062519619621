.imprimerChantierContainer {
    background-color: #eeeeee;
    margin: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
    max-width: 320px;
    border-radius: 4px;
}

.doc-content {
    margin: 20px 0 10px 0;

    .doc-user {
        display: flex;
        align-items: center;
        margin: 10px 0;

        p {
            margin-bottom: 0;
            margin-right: 15px;
        }

        .del-doc {
            cursor: pointer;
            min-height: 18px;
            min-width: 18px;
            background: url('../theme/img/icons/ic_close_18px.svg') no-repeat;
        }
    }
}

#docFile {
    margin-top: 10px;

}