.bandeau-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    span {
        font-size: 1.5rem;
    }
}

.dashboard {
    margin: 0 !important;


    .card-dashboard {

        md-card md-card-content {
            min-height: 280px;

            .card-bg-icon {
                pointer-events: none;
            }

            a {
                color: white;
            }
        }
    }

    .card-notifications {
        background-color: rgb(200, 50, 50);
    }


    .block-dashboard {
        height: 300px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        box-shadow: none;

        >a {
            height: 100%;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
            background: #e6e6e6;
            text-decoration: none;
        }

        .title-dashboard {
            font-weight: 700;
            padding-bottom: 10px;
            font-size: 1.8rem;
        }

        .icon {
            margin-bottom: 15px;
        }

        .users,
        .messagerie,
        .planspose,
        .reportingdet,
        .reportingsimpl,
        .plansposeatt {
            height: 100%;
            display: flex;
            color: rgba(0, 0, 0, 0.87);
            background-color: rgb(255, 255, 255);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
            -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
            -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
            -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
        }

        .users .icon {
            //background: url('../../dist/img/male-user-shadow.png') no-repeat;
            width: 80px;
            height: 80px;
            background-size: contain;
        }

        .messagerie .icon {
            // background: url('../../dist/img/envelope.png') no-repeat;
            width: 80px;
            height: 80px;
            background-size: contain;
        }

        .planspose .icon,
        .plansposeatt .icon {
            // background: url('../../dist/img/picture-file.png') no-repeat;
            width: 80px;
            height: 80px;
            background-size: contain;
        }

        .nbpdp {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 75%;

            .nbpdpatt {
                display: flex;
                align-items: center;

                .number {
                    font-weight: 700;
                    font-size: 7vh;
                    padding-right: 7px;
                }

                .en-attente {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .reportingdet .icon,
        .reportingsimpl .icon {
            //background: url('../../dist/img/data-analytics-ascending-line-chart.png') no-repeat;
            width: 80px;
            height: 80px;
            background-size: contain;
        }

        @media screen and (max-width: 1200px) {
            width: 50%;
            float: left;
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            float: left;
        }
    }
}