$vert: #009688;
$vert2: rgb(0, 131, 143);
$blanc: #ffffff;
$grisFonce: #757575;

.form-control {
    background-color: transparent !important;
}

button {
    font-weight: bold !important;
    min-height: 28px !important;
    height: unset !important;
    line-height: 28px !important;
    min-width: 72px !important;
    //background-color: #009688 !important;
    //padding: 0 10px !important;
}

.form-group {
    clear: both;

    &::after {
        content: "";
        clear: both;
    }
}

.btn-colored {
    background-color: $vert2 !important;
    color: black !important;
    color: $blanc !important;
}

.buttonp1 {
    padding: 10px 25px;
    color: white;
    background-color: #00838f;
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 3px;
    margin: 0;
}

//checkbox
.cb-perso {
    margin-left: 22px;

    input[type="checkbox"] {
        //visibility: hidden;
        display: none;
        width: 0;
        height: 0;

        &:checked {
            opacity: 1;
        }
    }

    input[type="checkbox"]+label {
        cursor: pointer;
    }

    input[type="checkbox"]+label::before {
        border: 2px solid $grisFonce;
        content: "\00a0";
        display: inline-block;
        font: 16px/1em sans-serif;
        height: 20px;
        margin: 0 .25em 0 0;
        padding: 0;
        vertical-align: top;
        width: 20px;
        margin-left: -20px;
        border-radius: 2px;
        //padding: 2px;
        transition: all 0.5s;
    }

    input[type="checkbox"]:checked+label::before {
        background: $vert2;
        color: $blanc;
        content: "\2713";
        text-align: center;
        border-color: $vert2;
    }

    input[type="checkbox"]:checked+label::after {
        font-weight: bold;
    }

    input[type="checkbox"]:focus+label::before {
        outline: rgb(59, 153, 252) auto 5px;
    }
}