.popupChantier {
    overflow-y: auto;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.5);

    .popupChantierContent {
        position: relative;
        margin: 250px auto;
        max-width: 760px;
        min-height: 250px;
        width: 100%;
        //display: flex;
        //flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;

        .topPopup {
            padding: 20px;

            .pictoClose {
                float: right !important;
            }
        }

        .incidents {
            .incident {
                display: none;

                img {
                    max-width: 100%;
                }

                &.active {
                    display: unset;
                }
            }
        }

        .bottomPopup {
            padding: 20px;

            >div {
                height: 48px;

                position: relative;
            }

            a {
                top: 50%;

                position: absolute;

                left: 50%;

                transform: translate(-50%, -50%);
            }
        }

    }
}