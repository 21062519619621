.pictoType {
    cursor: pointer;
    min-height: 48px;
    min-width: 48px;

}

.pictoTypeSmall {
    cursor: pointer;
    min-height: 24px;
    min-width: 24px;

}

.pictoIncident {
    @extend .pictoType;
    background: url('../theme/img/icons/ic_warning_48px.svg') no-repeat;

}

.pictoIncidentSmall {
    @extend .pictoTypeSmall;
    background: url('../theme/img/icons/ic_warning_24px.svg') no-repeat;

}

.pictoClose {
    @extend .pictoType;
    background: url('../theme/img/icons/ic_close_48px.svg') no-repeat;

}

.pictoArrowLeft {
    @extend .pictoType;
    background: url('../theme/img/icons/ic_arrow_back_48px.svg') no-repeat;

}

.pictoArrowRight {
    @extend .pictoType;
    background: url('../theme/img/icons/ic_arrow_forward_48px.svg') no-repeat;
}

.pictoVisibility {
    @extend .pictoType;
    background: url('../theme/img/icons/ic_visibility_48px.svg') no-repeat;
}

.pictoVisibilitySmall {
    @extend .pictoTypeSmall;
    background: url('../theme/img/icons/ic_visibility_24px.svg') no-repeat;

}


.pictoRemove {
    @extend .pictoType;
    background: url('../theme/img/icons/ic_delete_48px.svg') no-repeat;

}

.pictoRemoveSmall {
    @extend .pictoTypeSmall;
    background: url('../theme/img/icons/ic_delete_24px.svg') no-repeat;

}

.pictoEdit {
    @extend .pictoType;
    background: url('../theme/img/icons/ic_edit_48px.svg') no-repeat;
}

.pictoEditSmall {
    @extend .pictoTypeSmall;
    background: url('../theme/img/icons/ic_edit_24px.svg') no-repeat;

}

.pictoPdf {
    @extend .pictoType;
    background: url('../img/pdf_img.png') no-repeat;
    background-size: 38px 48px;
}